import { bindable } from 'aurelia-framework';
import moment from 'moment';

export class LeadActivityTimeline {
    @bindable activities;

    days = [];

    activitiesChanged() {
        this._load();
    }

    _load() {
        try {
            this.days = [];
            this.activities.forEach(a => {
                const d = moment(a.date).format('ll');
                let day = this.days.find(x => x.date === d);
                if (!day) {
                    this.days.push({ date: d, activities: [] });
                    day = this.days[this.days.length - 1];
                }
                day.activities.push(a);
            });
        } catch (err) {
            console.log(err);
        }
    }
}
