import { bindable, observable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Leads } from 'services/leads';
import { Members } from 'services/members';
import { TIME_PERIOD } from 'common/constants';

export class LeadKpis {
    static inject = [Element, Security, Leads, Members];
    _element;
    _security;
    _leads;
    _members;

    @bindable agentId;
    @bindable reload;
    agent;

    kpiTimePeriods = TIME_PERIOD.common();
    kpiTimePeriod = TIME_PERIOD.default();
    @observable kpiCustomRange;

    constructor(element, security, leads, members) {
        this._element = element;
        this._security = security;
        this._leads = leads;
        this._members = members;
        this.agent = this._security.agent;
    }

    attached() {
        this._isAttached = true;
        this.loadKpis();
    }

    agentIdChanged() {
        if (!this._isAttached) return;
        this.loadKpis();
    }

    reloadChanged() {
        if (!this.reload) return;
        this.loadKpis(false);
    }

    selectKpiTimePeriod(timePeriod) {
        this.kpiTimePeriod = timePeriod;
        if (this.kpiTimePeriod === 'custom') return;
        this._kpiStart = undefined;
        this._kpiEnd = undefined;
        this.loadKpis(false);
    }

    kpiCustomRangeChanged() {
        if (!this.kpiCustomRange || this.kpiCustomRange.length !== 2) return;
        this._kpiStart = this.kpiCustomRange[0].format('MM/DD/YYYY');
        this._kpiEnd = this.kpiCustomRange[1].format('MM/DD/YYYY');
        this.loadKpis(false);
    }

    async _loadAgent() {
        try {
            if (this.agentId) {
                this.agent = await this._members.getMember(this.agentId);
            } else {
                this.agent = this._security.agent;
            }
        } catch (err) {
            console.log(err);
        }
    }

    async loadKpis(loadAgent = true) {
        try {
            if (loadAgent) {
                this._loadAgent();
            }
            this.loadingKpis = true;
            const kpiData = await this._leads.kpis(this.kpiTimePeriod, this.agentId, this._kpiStart, this._kpiEnd);
            const kpiGroups = [];
            const groupCount = Math.ceil(kpiData.kpis.length / 4);
            for (let i = 0; i < groupCount; i++) kpiGroups.push({ expanded: i === 0, showExpander: i > 0, kpis: [] });
            var groupIndex = 0;
            kpiData.kpis.forEach(kpi => {
                if (kpiGroups[groupIndex].kpis.length === 4) {
                    groupIndex++;
                }
                kpiGroups[groupIndex].kpis.push(kpi);
            });
            this.kpiGroups = kpiGroups;
            this.progressPeriodName = kpiData.progressPeriodName;
        } catch (err) {
            console.log(err);
        } finally {
            this.loadingKpis = false;
        }
    }

    kpiColor(kpi, index) {
        try {
            const colorIndex = index % 4;
            switch (colorIndex) {
                case 0: return 'lpfn-kpi-success';
                case 1: return 'lpfn-kpi-warning';
                case 2: return 'lpfn-kpi-info';
                case 3: return 'lpfn-kpi-danger';
                default: return 'lpfn-kpi-success'
            }
        } catch (err) {
            console.log(err);
            return 'lpfn-kpi-danger';
        }
    }

    viewLeads(kpi) {
        if (!kpi.canViewLeads) return;
        let ids = undefined;
        if (kpi.isViewing) {
            // turn this one off
            kpi.isViewing = false;
        } else {
            this.kpiGroups.forEach(kpiGroup => {
                kpiGroup.kpis.forEach(kpi => kpi.isViewing = false);
            });
            kpi.isViewing = true;
            ids = kpi.leadIds;
        }
        this._element.dispatchEvent(new CustomEvent('view-leads', { bubbles: true, detail: { ids } }));
    }
}
