import { bindable } from 'aurelia-framework';
export class LeadStatus {
    @bindable status;
    @bindable active = true;

    attached() {
        this._setIcon();
    }

    statusChanged() {
        this._setIcon();
    }

    _setIcon() {
        switch (this.status) {
            case 'hot':
                this.icon = 'fa-duotone fa-fire-flame';
                break;
            case 'warm':
                this.icon = 'fa-duotone fa-fire-flame';
                break;
            case 'cold':
                this.icon = 'fa-duotone fa-snowflake';
                break;
            case 'archived':
                this.icon = 'fa-duotone fa-archive';
                break;
            default:
                this.icon = '';
                break;
        }
    }
}