import { customElement, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Leaderboards } from 'services/leaderboards';
import moment from 'moment';
import { c } from 'common/common';

@customElement('leaderboard')
export class Leaderboard {
    static inject = [Element, EventAggregator, Leaderboards];
    _element;
    _ea;
    @bindable view = 'list';
    @bindable code;
    @bindable lineOfBusiness;
    @bindable scroll = true;
    @bindable priorWeeksAllowed;

    _leaderboards;
    leaderboard;
    currentDate;
    momentTimePeriod;

    constructor(element, ea, leaderboards) {
        this._element = element;
        this._ea = ea;
        this._leaderboards = leaderboards;
    }

    attached() {
        this._isAttached = true;
        this._setColoring();
        this._load();
    }

    viewChanged() {
        this._setColoring();
    }

    _setColoring() {
        try {
            const widgetEl = this._element.closest('.grid-stack-item-content.lpfn-dashboard-widget');
            if (!widgetEl) return;
            if (this.view === 'podium') {
                const color = getComputedStyle(document.body).getPropertyValue('--lpfn-secondary');
                widgetEl.style.backgroundColor = color;
            } else {
                const color = getComputedStyle(document.body).getPropertyValue('--kt-card-bg');
                widgetEl.style.backgroundColor = color;
            }
        } catch (err) {
            console.log(err);
        }
    }

    codeChanged() {
        if (!this._isAttached) return;
        this._load();
    }

    lineOfBusinessChanged() {
        if (!this._isAttached) return;
        this._load();
    }

    previous() {
        if (!this.canGoPrevious) return;
        this.currentDate = this.currentDate.add(-1, this.momentTimePeriod);
        this._load();
    }

    next() {
        if (!this.canGoNext) return;
        this.currentDate = this.currentDate.add(1, this.momentTimePeriod);
        this._load();
    }

    current() {
        this.currentDate = undefined;
        this._load();
    }

    async refreshData() {
        this.refreshing = true;
        if (this.leaderboard) this.leaderboard.leaders = [];
        await this._load();
        this.refreshing = false;
    }

    async _load() {
        if (!this.code) return;
        try {
            this.loading = true;
            let initialLoad = false;
            if (!this.currentDate) {
                initialLoad = true;
                this.currentDate = moment();
            }
            this.leaderboard = await this._leaderboards.byCode(this.code, this.lineOfBusiness, undefined, this.currentDate);
            switch (this.leaderboard.timePeriod) {
                case 'Weekly': this.momentTimePeriod = 'w'; break;
                default: this.momentTimePeriod = 'w'; break;
            }
            const nextDate = this.currentDate.clone().add(1, this.momentTimePeriod);
            this.canGoNext = !nextDate.isAfter(moment().endOf('day'));
            const start = moment(this.leaderboard.start);
            if (initialLoad && this.priorWeeksAllowed !== undefined) {
                const goBackWeeks = -1 * this.priorWeeksAllowed;
                this.earliestDate = start.clone().add(goBackWeeks, this.momentTimePeriod);
            }
            if (!this.earliestDate) this.canGoPrevious = true;
            else {
                const previousPeriodStartDate = start.clone().add(-1, this.momentTimePeriod);
                this.canGoPrevious = previousPeriodStartDate.isSameOrAfter(this.earliestDate);
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    openProfile(member) {
        this._ea.publish(c.EventKeys.site.openProfile, { member });
    }
}
