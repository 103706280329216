export class Lightbox {
    static inject = [Element];
    _element;
    displayLightbox = false;
    autoHeight;
    zIndex = 2000;

    constructor(element) {
        this._element = element;
    }

    open(autoHeight = false, fullHeight = false, zIndex = undefined) {
        this.autoHeight = autoHeight;
        this.fullHeight = fullHeight;
        this.displayLightbox = true;
        if (zIndex) this.zIndex = zIndex;
    }

    close() {
        this.displayLightbox = false;
        this._element.dispatchEvent(new CustomEvent('closed', { bubbles: true, detail: {}}));
    }
}
