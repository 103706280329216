import { bindable } from 'aurelia-framework';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { AutocompleteBase } from 'resources/base-classes/autocomplete-base';

export class LeadAutocomplete extends AutocompleteBase {
    static inject = [Element, Api, I18n];
    _i18n;

    @bindable forDownline = false;
    @bindable label = (result, long = false) => {
        if (!result) return '';
        if (!long) return result.fullName;
        return `${result.fullName}<br>${result.city} ${result.state}<br><label>${this._i18n.tr('county')}</label>: ${result.county}`;
    };

    constructor(element, api, i18n) {
        super(element, api, 'leads/autocomplete-search', (value) => {
                return {
                    searchOn: value,
                }
            });
        this._i18n = i18n;
    }

    bind() {
        this._bind();
    }
}
