import { bindable } from 'aurelia-framework';
import { isVideoUrl } from 'common/common';

export class LinkListsSection {
    @bindable linkLists;
    @bindable trPath;

    leftLinkLists;
    rightLinkLists;

    constructor() {}

    bind() {
        this._initialize();
    }

    linkListsChanged() {
        this._initialize();
    }

    _initialize() {
        try {
            this.leftLinkLists = [];
            this.rightLinkLists = [];
            if (!this.linkLists) return;

            const linkLists = [];
            if (this.linkLists.length) {
                // Start showing top 3
                this.linkLists.forEach(linkList => {
                    if (!linkList.links.length) return;
                    linkList.displayAll = false;
                    for (let i = 0; i < linkList.links.length; i++) {
                        linkList.links[i].display = i <= 2;
                        linkList.links[i].isVideo = isVideoUrl(linkList.links[i].src);
                    }
                    linkLists.push(linkList);
                });
                // odd indexes on left, event indexes on right
                this.leftLinkLists = [];
                this.rightLinkLists = [];
                for (let i = 0; i < linkLists.length; i++) {
                    if (i % 2 === 0) {
                        this.leftLinkLists.push(linkLists[i]);
                    } else {
                        this.rightLinkLists.push(linkLists[i]);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
}
