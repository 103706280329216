import { bindable } from 'aurelia-framework';
import { c } from 'common/common';

export class Call {
    @bindable activity;

    displayRecordingUrl() {
        this.viewRecording = !this.viewRecording;
    }

    displayTranscript() {
        this.viewTranscript = !this.viewTranscript;
    }
}