import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Dialer } from 'services/dialer';
import { Notifier } from 'common/ui';
import { DialogService } from 'aurelia-dialog';
import { MemberConfig } from 'services/member-config';
import { EditNote } from '../notes/dialogs/edit-note';
import { c } from 'common/common';
import { ABOUT_TYPE } from 'common/constants';
import { EditToDo } from '../to-dos/dialogs/edit-to-do';

PLATFORM.moduleName('../notes/dialogs/edit-note');
PLATFORM.moduleName('../to-dos/dialogs/edit-to-do');

export class LeadName {
    static inject = [EventAggregator, Dialer, Notifier, DialogService, MemberConfig];
    _ea;
    _dialer;
    _notifier;
    _dialogService;
    _memberConfig;

    @bindable lead;
    @bindable canAutoDial;

    constructor(ea, dialer, notifier, dialogService, memberConfig) {
        this._ea = ea;
        this._dialer = dialer;
        this._notifier = notifier;
        this._dialogService = dialogService;
        this._memberConfig = memberConfig;
    }

    attached() {
        this.canBookAppt = this._memberConfig.value('lead-scheduler-page');
    }

    openLead() {
        this._ea.publish(c.EventKeys.site.openLead, { leadId: this.lead.id });
    }

    addNote() {
        const model = { type: ABOUT_TYPE.Lead, aboutId: this.lead.id, note: undefined };
        this._dialogService.open({ viewModel: EditNote, model, ignoreTransitions: true });
    }

    addToDo() {
        const model = { type: ABOUT_TYPE.Lead, aboutId: this.lead.id, aboutName: this.lead.fullName, toDo: undefined };
        this._dialogService.open({ viewModel: EditToDo, model, ignoreTransitions: true });
    }

    async autoDial() {
        try {
            const response = await this._dialer.call([this.lead.id]);
            if (response.succeeded) this._notifier.success('auto-dialer-success');
            else this._notifier.error(response.message);
        } catch (err) {
            console.log(err);
            this._notifier.error('auto-dialer-error');
        }
    }
}