import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { openVideo } from 'common/common';
import { c } from 'common/common';
import environment from '../../../../config/environment.json';

export class LinkLists {
    static inject = [EventAggregator];
    @bindable linkLists;
    @bindable trPath;

    constructor(ea) {
        this._ea = ea;
    }

    bind() {
        this._initialize();
    }

    linkListsChanged() {
        this._initialize();
    }

    _initialize() {
        if (!this.linkLists) return;
        this.linkLists.forEach(ll => {
            if (ll.links && ll.links.length) {
                ll.links.forEach(l => {
                    l.id = c.Helpers.uniqueId();
                    if (l.src && l.src.indexOf('api/file') === 0) {
                        l.isVideo = true;
                        l.fullHeight = true;
                        l.src += '?dispositionType=inline';
                    }
                    if (l.src && l.src.indexOf('http') !== 0) l.src = `${environment.static}/${l.src}`;
                    if (l.fileSrc && l.fileSrc.indexOf('http') !== 0) l.fileSrc = `${environment.static}/${l.fileSrc}`;
                });
            }
        });
    }

    toggleDescription(link) {
        link.showDescription = !link.showDescription;
    }

    playVideo(link) {
        openVideo(link.src, this._ea, link.fullHeight);
    }
}
