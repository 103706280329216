import { bindable } from 'aurelia-framework';
import { LeadScripts } from 'services/lead-scripts';
import { Leads } from 'services/leads';

export class LeadScript {
    static inject = [LeadScripts, Leads];
    _leadScripts;
    _leads;

    @bindable leadId;
    @bindable scriptId;
    @bindable minimal = false;

    script;
    scriptIdsInOrder = [];

    constructor(leadScripts, leads) {
        this._leadScripts = leadScripts;
        this._leads = leads;
    }

    async leadIdChanged() {
        if (!this.leadId) return;
        try {
            this.script = await this._leadScripts.scriptForLead(this.leadId);
            this._initialize();
        } catch (err) {
            console.log(err);
        }     
    }

    async scriptIdChanged() {
        if (!this.scriptId) return;
        try {
            this.script = await this._leadScripts.sampleScript(this.scriptId);
            this._initialize();
        } catch (err) {
            console.log(err);
        }     
    }

    _initialize() {
        if (!this.script) return;
        this.currentScript = this.script.find(x => x.type === 'start');
        if (!this.currentScript) return;
        this.scriptIdsInOrder.push(this.currentScript.id);
        this._loadLead();
    }

    async _loadLead() {
        if (!this.leadId) {
            // Loading by script with sample data
            this.lead = { fullName: '', email: '' };
            return;
        }
        try {
            this.lead = await this._leads.byId(this.leadId);
        } catch (err) {
            console.log(err);
        }
    }

    optionClicked(option) {
        if (option.toScriptId) {
            this.currentScript = this.script.find(x => x.id === option.toScriptId);
            if (!this.currentScript) return;
            this.scriptIdsInOrder.push(this.currentScript.id);
        }
    }

    goBack() {
        try {
            if (!this.scriptIdsInOrder.length) return;
            this.scriptIdsInOrder.pop();
            const goToId = this.scriptIdsInOrder[this.scriptIdsInOrder.length - 1];
            this.currentScript = this.script.find(x => x.id === goToId);
        } catch (err) {
            console.log(err);
        }
    }
}