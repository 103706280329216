import { bindable, observable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Leads } from 'services/leads';
import { Members } from 'services/members';

export class LeadKpi {
    static inject = [Element, Security, Leads, Members];
    _element;
    _security;
    _leads;
    _members;

    @bindable kpi;
    @bindable view;
    @bindable timePeriod;

    constructor(element, security, leads, members) {
        this._element = element;
        this._security = security;
        this._leads = leads;
        this._members = members;
    }

    attached() {
        this._isAttached = true;
        this.loadKpis();
    }

    selectTimePeriod(data) {
        this._selectingTimePeriod = true;
        this.timePeriod = data.timePeriod;
        this._timePeriodStart = data.start;
        this._timePeriodEnd = data.end;
        this.loadKpis();
    }

    kpiChanged() {
        if (!this._isAttached || !this.kpi) return;
        this.loadKpis();
    }

    viewChanged() {
        if (!this._isAttached || !this.view) return;
        this.loadKpis();
    }

    timePeriodChanged() {
        if (!this._isAttached || !this.timePeriod) return;
        if (this._selectingTimePeriod) {
            this._selectingTimePeriod = false;
            return;
        }
        this.loadKpis();
    }

    async loadKpis() {
        try {
            this.loading = true;
            const kpiData = await this._leads.kpis(this.timePeriod, undefined, this._timePeriodStart, this._timePeriodEnd, this.kpi, this.view);
            kpiData.kpis[0].cssClass = this.kpiColor(kpiData.kpis[0].index);
            this.leadKpi = kpiData.kpis[0];
            this.progressPeriodName = kpiData.progressPeriodName;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    kpiColor(kpi) {
        try {
            const colorIndex = kpi.index % 4;
            switch (colorIndex) {
                case 0: return 'lpfn-kpi-success';
                case 1: return 'lpfn-kpi-warning';
                case 2: return 'lpfn-kpi-info';
                case 3: return 'lpfn-kpi-danger';
                default: return 'lpfn-kpi-success'
            }
        } catch (err) {
            console.log(err);
            return 'lpfn-kpi-danger';
        }
    }
}
